const LAST_ITEMS = [
    {
		type: 'AK-47',
		name: 'Aquamarine Revenge',
		color: '#D85752',
		image: 'assets/img/items/item-1.png'
	},
	{
		type: 'Desert Eagle',
		name: 'Pilot',
		color: '#7F50F6',
		image: 'assets/img/items/item-2.png'
	},
	{
		type: 'MP9',
		name: 'Bulldozer',
		color: '#7F50F6',
		image: 'assets/img/items/item-3.png'
	},
	{
		type: 'M4A4',
		name: 'Poseidon',
		color: '#C142DE',
		image: 'assets/img/items/item-4.png'
	},
	{
		type: '★ Hand Wraps',
		name: 'Badlands',
		color: '#D85752',
		image: 'assets/img/items/item-5.png'
	},
	{
		type: '★ Ursus Knife',
		name: 'Ultraviolet',
		color: '#D85752',
		image: 'assets/img/items/item-6.png'
	},
	{
		type: 'P250',
		name: 'Asiimov',
		color: '#C142DE',
		image: 'assets/img/items/item-7.png'
	},
	{
		type: 'AWP',
		name: 'Chromatic Aberration',
		color: '#D85752',
		image: 'assets/img/items/item-8.png'
	},
	{
		type: 'M4A1-S',
		name: 'Decimator',
		color: '#C142DE',
		image: 'assets/img/items/item-9.png'
	},
	{
		type: 'M4A1-S',
		name: 'Cyrex',
		color: '#D85752',
		image: 'assets/img/items/item-10.png'
	},
];


export default LAST_ITEMS;