// About.js
import React from 'react';

const About = () => (
  <div>
    <div className="description">
      <p className="title">About <span className="name">VOLTSKINS</span></p>
    </div>
    <p className="text">
      Welcome to <span className="name">VOLTSKINS</span>, your ultimate destination for winning CS2 game skins! Our platform offers exciting opportunities to participate in various events and competitions where you can win unique and valuable CS2 skins. Once you have won your desired skins, you have the option to sell them on Skinport, a trusted marketplace for trading and selling game items. Join us today and experience the thrill of winning and trading CS2 skins!
    </p>
  </div>
);

export default About;
