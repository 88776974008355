// App.js
import React from 'react';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import ModalContainer from './components/ModalContainer';
import Description from './components/Description';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FreeCase from './components/FreeCase';
import Privacy from './components/Privacy';
import About from './components/About';
import Cookie from './components/Cookie';
import Terms from './components/Terms';

import './css/style.css';
import './css/slick.css';
import './css/roll.css';
import './css/modal.css';
import './css/media.css';
import './css/init.css';
import './css/aos.css';
import './css/cookie.css';

const App = () => (
  <Router>
    <div className="content">
      <div className="app">
        <Header />
        <Cookie />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/about" element={<About />} />
        </Routes>
        <Footer />
      </div>
    </div>
  </Router>
);

export default App;

