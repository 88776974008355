const ROLL_ITEMS = [
    {
		type: 'AK-47',
		name: 'Aquamarine Revenge',
		color: '#D85752',
		image: 'assets/img/items/item-1.png'
	},
	{
		type: 'Desert Eagle',
		name: 'Pilot',
		color: '#7F50F6',
		image: 'assets/img/items/item-2.png'
	},
	{
		type: 'MP9',
		name: 'Bulldozer',
		color: '#7F50F6',
		image: 'assets/img/items/item-3.png'
	},
	{
		type: 'M4A4',
		name: 'Poseidon',
		color: '#C142DE',
		image: 'assets/img/items/item-4.png'
	},
	{
		type: '★ Hand Wraps',
		name: 'Badlands',
		color: '#D85752',
		image: 'assets/img/items/item-5.png'
	},
	{
		type: '★ Ursus Knife',
		name: 'Ultraviolet',
		color: '#D85752',
		image: 'assets/img/items/item-6.png'
	},
	{
		type: 'P250',
		name: 'Asiimov',
		color: '#C142DE',
		image: 'assets/img/items/item-7.png'
	},
	{
		type: 'AWP',
		name: 'Chromatic Aberration',
		color: '#D85752',
		image: 'assets/img/items/item-8.png'
	},
	{
		type: 'M4A1-S',
		name: 'Decimator',
		color: '#C142DE',
		image: 'assets/img/items/item-9.png'
	},
	{
		type: 'M4A1-S',
		name: 'Cyrex',
		color: '#D85752',
		image: 'assets/img/items/item-10.png'
	},
    {
		type: '★ M9 Bayonet',
		name: 'Tiger Tooth',
		color: '#D85752',
		image: 'assets/img/items/item-11.png',
		canDrop: true
	},
    {
		type: 'AK-47',
		name: 'Case Hardened',
		color: '#C142DE',
		image: 'assets/img/items/item-12.png',
		canDrop: true
	},
    {
		type: 'M4A4',
		name: 'Hellfire',
		color: '#C142DE',
		image: 'assets/img/items/item-13.png',
		canDrop: true
	},
    {
		type: 'P250',
		name: 'See Ya Later',
		color: '#D85752',
		image: 'assets/img/items/item-14.png',
		canDrop: true
	},
    {
		type: 'MAC-10',
		name: 'Curse',
		color: '#7F50F6',
		image: 'assets/img/items/item-15.png',
		canDrop: true
	},
    {
		type: '★ Bayonet',
		name: 'Slaughter',
		color: '#D85752',
		image: 'assets/img/items/item-16.png',
		canDrop: true
	},
    {
		type: 'Glock-18',
		name: 'Fade',
		color: '#7F50F6',
		image: 'assets/img/items/item-17.png',
		canDrop: true
	},
    {
		type: 'M4A1-S',
		name: 'Blue Phosphor',
		color: '#C142DE',
		image: 'assets/img/items/item-18.png',
		canDrop: true
	},
    {
		type: 'AWP',
		name: 'Containment Breach',
		color: '#D85752',
		image: 'assets/img/items/item-19.png',
		canDrop: true
	},
    {
		type: 'AK-47',
		name: 'Redline',
		color: '#C142DE',
		image: 'assets/img/items/item-20.png',
		canDrop: true
	},
    {
		type: '★ Talon Knife',
		name: 'Marble Fade',
		color: '#D85752',
		image: 'assets/img/items/item-21.png',
		canDrop: true
	},
    {
		type: '★ Driver Gloves',
		name: 'Snow Leopard',
		color: '#D85752',
		image: 'assets/img/items/item-22.png',
		canDrop: true
	},
];
export default ROLL_ITEMS;