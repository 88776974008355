// components/LatestSkins.js
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LAST_ITEMS from "./LastItems";
const TIME_SPANS = [2, 3, 3, 4, 10, 12, 13, 13, 13];

const LatestSkins = () => {
    const [totalSlides, setTotalSlides] = useState(0);

    useEffect(() => {
        setTotalSlides(LAST_ITEMS.length);
    }, []);

    const settings = {
        infinite: true,
        slidesToShow: 7,
        slidesToScroll: 7,
        draggable: false,
        autoplay: false,
        dots: true,
        appendDots: dots => <div className="pages">{dots}</div>,
        responsive: [
            { breakpoint: 1160, settings: { slidesToShow: 6, slidesToScroll: 6 }},
            { breakpoint: 1010, settings: { slidesToShow: 5, slidesToScroll: 5 }},
            { breakpoint: 850, settings: { slidesToShow: 4, slidesToScroll: 4 }},
            { breakpoint: 700, settings: { slidesToShow: 3, slidesToScroll: 3 }},
            { breakpoint: 540, settings: { slidesToShow: 2, slidesToScroll: 2 }},
            { breakpoint: 370, settings: { slidesToShow: 1, slidesToScroll: 1 }},
        ],
    };

    return (
        <div className="latest">
            <div className="title">
                <div className="left">
                    <img src="assets/img/latest-dot.svg" alt="latest-dot" />
                    <p>Latest skins <span className="c">released</span></p>
                </div>
                <p className="right">
                    Total skins released: <span className="v">8,278</span>
                </p>
            </div>
            <Slider {...settings} className="list">
                {LAST_ITEMS.map((item, index) => {
                    const timeSpan = TIME_SPANS[index % TIME_SPANS.length];
                    const shadowStyle = item.color ? { backgroundImage: `linear-gradient(to right, transparent, ${item.color}, transparent)` } : {};

                    return (
                        <div key={index}>
                            <div className="item">
                                <p className="time">{timeSpan} min. ago</p>
                                <img src={item.image} className="image" alt={item.name} />
                                <div className="name">
                                    <p>{item.type}</p>
                                    <p>{item.name}</p>
                                </div>
                                <div className="shadow" style={shadowStyle}></div>
                            </div>
                        </div>
                    );
                })}
            </Slider>
            <div className="pages"></div>
        </div>
    );
};

export default LatestSkins;