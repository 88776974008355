// components/Description.js
import React from 'react';

const Description = () => (
   <div className="description">
      <p className="title">FREE CASE OPENING SIMULATOR PROVIDED BY <span className="name">VOLTSKINS</span></p>
      <p className="text">
         <span className="b">VOLTSkins</span> are holding a promotion. Completely free case opening simulations. Open and remove skin.
      </p>
   </div>
);

export default Description;
