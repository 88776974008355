import React from 'react';

const Modal = ({ onClose }) => {
    return (
        <div className="modal-container" onClick={onClose}>
            <div className="modal" data-modal="wrong">
                <button className="close">
                    <img src="assets/img/close.svg" alt="Close" />
                </button>
                <p className="modal-title">Something</p>
                <p className="modal-subtitle">goes wrong</p>
                <p className="modal-text">You should be signed in to do this</p>
                <div className="modal-buttons">
                    <button className="signin ibehadn7jeep">
                        <img src="assets/img/steam.svg" alt="Sign in through Steam" />
                        <p>SIGN IN THROUGH STEAM</p>
                    </button>
                    <button className="cancel" onClick={onClose}>
                        <p>CANCEL</p>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
