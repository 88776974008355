import React, { useState, useEffect, useRef } from 'react';
import { shuffle_array, random_int } from './utils';
import $ from 'jquery';
import axios from 'axios';
import ROLL_ITEMS from './RollItems';
import Description from './Description';
import LatestSkins from './LatestSkins';
import Modal from './Modal';
import Image from '../img/Image';
import caseImage from '../img/caseImg.png';

const ROLL_DURATION = 10;
const ROLL_TIMING = 'cubic-bezier(0.25, 0.1, 0.5, 1)';
const ROLL_NOT_PERSISTENT = false;
const ROLL_NO_WIN_DISPLAY = false;

const ROLL_ITEM_WIDTH = 150;
const ROLL_SPACE_BETWEEN = 16;

const Main = () => {
    const [rollStarted, setRollStarted] = useState(false);
    const [rollDone, setRollDone] = useState(false);
    const [rollWinItem, setRollWinItem] = useState(null);
    const [rollTotalArray, setRollTotalArray] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const rollListRef = useRef(null);

    useEffect(() => {
        initRoll();
    }, []);

    useEffect(() => {
        if (rollStarted && rollListRef.current) {
            const handleTransitionEnd = () => {
                if (!rollDone && !ROLL_NO_WIN_DISPLAY) {
                    setTimeout(() => {
                        rollOpenResultAfter();
                    }, 500);
                }
            };
            const rollListElement = rollListRef.current;
            rollListElement.addEventListener('transitionend', handleTransitionEnd);

            return () => {
                rollListElement.removeEventListener('transitionend', handleTransitionEnd);
            };
        }
    }, [rollStarted, rollDone]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('authorize')) {
            openModal('wrong');
        }
    }, []);

    const initRoll = () => {
        if (ROLL_ITEMS.length < 2) {
            $('.roll-zone').html('<p class="roll-error">Service is temporarily unavailable</p>');
            $('.roll-zone').css('display', '');
            return;
        }

        fillRollZone();
        fillRollItems();

        const won = localStorage.getItem('roll_win_item');
        console.log("won = ", won);
        if (won && !ROLL_NOT_PERSISTENT) {
            const wonItem = JSON.parse(won);
            setRollWinItem(wonItem);
            rollOpenResultInstant(wonItem);
            return;
        }
    };

    const fillRollZone = () => {
        $('.roll-zone').html(`
            <div class="roll-start">
                <img src="assets/img/case.png">
            </div>
            <div class="roll-process" style="display: none;">
                <div class="roll-list" style="gap: ${ROLL_SPACE_BETWEEN}px"></div>
                <div class="roll-overlay"></div>
                <div class="roll-handle"></div>
            </div>
            <div class="roll-result" style="display: none;"></div>
        `);
    };

    const fillRollItems = () => {
        console.log("fillRollItems start");
        const itemsArray = shuffle_array([...ROLL_ITEMS]);

        let newRollTotalArray = [];
        for (let i = 0; i < Math.ceil(500 / ROLL_ITEMS.length); i++) {
            for (let item of itemsArray) {
                const shadowStyle = item.color ? `background: ${item.color};` : '';
                const hrStyle = item.color ? `background-image: linear-gradient(to right, transparent, ${item.color}, transparent);` : '';

                $('.roll-list').append(`
                    <div class="roll-item" style="width: ${ROLL_ITEM_WIDTH}px">
                        <img src="${item.image}">
                        <div class="hr" style="${hrStyle}"></div>
                        <p>${item.type}<br>${item.name}</p>
                        <div class="shadow" style="${shadowStyle}"></div>
                    </div>
                `);
            }
            newRollTotalArray = newRollTotalArray.concat(itemsArray);
        }
        setRollTotalArray(newRollTotalArray);
    };

    const rollButtonClick = () => {
        if (rollDone) {
            return;
        }
        if (rollStarted) {
            return;
        }

        setRollStarted(true);
        $('.roll-start').css('opacity', '0');
        $('.roll-open button').attr('disabled', 'disabled');
        setTimeout(() => {
            $('.roll-start').css('display', 'none');
            $('.roll-process').css('opacity', '0').css('display', '');
            setTimeout(() => {
                $('.roll-process').css('opacity', '1');
                rollStartMove();
            }, 1);
        }, 200);
    };

    const rollStartMove = () => {
        const width = ROLL_ITEM_WIDTH;
        const space = ROLL_SPACE_BETWEEN;
        const initPosition = width + width / 2 - space - width * 3 - space * 3;

        const itemsBeforeHandle = 5;

        let index = random_int(400, 400 + ROLL_ITEMS.length);

        let winItem = rollTotalArray[index + itemsBeforeHandle];

        let pickThreshold = 0;
        while (!winItem.canDrop) {
            if (pickThreshold > ROLL_ITEMS.length) break;
            index++;
            winItem = rollTotalArray[index + itemsBeforeHandle];
            pickThreshold++;
        }
        setRollWinItem(winItem);
        console.log("ROLL_NOT_PERSISTENT = ", ROLL_NOT_PERSISTENT);
        if (!ROLL_NOT_PERSISTENT) {
            console.log("localStorage.setItem = winItem = ", winItem);
            localStorage.setItem('roll_win_item', JSON.stringify(winItem));
        }

        const shift = (width + space) * index;
        const finalPosition = Math.abs(initPosition) + shift;

        $('.roll-list').css('left', `${initPosition}px`);
        $('.roll-list').css('transition', `all ${ROLL_DURATION}s ${ROLL_TIMING}`);
        setTimeout(() => {
            $('.roll-list').css('left', `${-finalPosition}px`);
        }, 10);

        // Set a timer to call initRoll after ROLL_DURATION + 1 seconds
        setTimeout(() => {
            initRoll();
        }, (ROLL_DURATION + 1) * 1000);
    };

    const rollOpenResult0 = () => {
        setRollDone(true);
        if (!rollWinItem) return;

        const shadowStyle = rollWinItem.color ? `background: ${rollWinItem.color};` : '';

        $(".roll-result").html(`
            <img src="assets/img/roll-result-side.svg" class="roll-result-side-1">
            <img src="assets/img/roll-result-side.svg" class="roll-result-side-2">
            <div class="roll-item" style="width: ${ROLL_ITEM_WIDTH}px">
                <img src="${rollWinItem.image}">
                <p>${rollWinItem.type}<br>${rollWinItem.name}</п>
                <div class="shadow" style="${shadowStyle}"></div>
            </div>
        `);
        onRollEnded();
        
    };

    const rollOpenResultAfter = () => {
        console.log("rollOpenResultAfter start");
        rollOpenResult0();
        $('.roll-process').css('opacity', '0');
        setTimeout(() => {
            $('.roll-process').css('display', 'none');
            $('.roll-result').css('opacity', '0').css('display', '');
            setTimeout(() => {
                $('.roll-result').css('opacity', '1');
                $('.roll-open button').html('WITHDRAW').removeAttr('disabled');
            }, 1);
        }, 200);
    };

    const rollOpenResultInstant = (winItem) => {
        setRollDone(true);
        setRollWinItem(winItem);
    
        const shadowStyle = winItem.color ? `background: ${winItem.color};` : '';
    
        $(".roll-result").html(`
            <img src="assets/img/roll-result-side.svg" class="roll-result-side-1">
            <img src="assets/img/roll-result-side.svg" class="roll-result-side-2">
            <div class="roll-item" style="width: ${ROLL_ITEM_WIDTH}px">
                <img src="${winItem.image}">
                <p>${winItem.type}<br>${winItem.name}</p>
                <div class="shadow" style="${shadowStyle}"></div>
            </div>
        `);
    
        $('.roll-start').css('display', 'none');
        $('.roll-process').css('display', 'none');
        $('.roll-result').css('display', '');
        $('.roll-open button').html('WITHDRAW').removeAttr('disabled');
    
        // Проверка и установка состояния в localStorage
        if (!localStorage.getItem('reloadDone')) {
            localStorage.setItem('reloadDone', 'true');
            window.location.reload();
        }
    };
    

    const onRollEnded = () => {
        let shadowStyle = rollWinItem.color ? `background-image: linear-gradient(to right, transparent, ${rollWinItem.color}, transparent);` : '';
        $(".latest .list").slick('slickAdd', `
            <div class="list-item">
                <div class="item-image">
                    <img src="${rollWinItem.image}">
                    <div class="shadow" style="${shadowStyle}"></div>
                </div>
                <p>${rollWinItem.type}<br>${rollWinItem.name}</p>
            </div>
        `);
        $(".roll-open").addClass("opened");
    };

    const openModal = (type) => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const [auth, setAuth] = useState({ loggedIn: false, username: '' });
    useEffect(() => {
        axios.get('/auth.php')
          .then(response => {
            setAuth(response.data);
          })
          .catch(error => {
            console.error('There was an error fetching the auth data!', error);
          });
      }, []);
    
      const handleSignUpClick = () => {
        window.location.href = 'mapper.php';
      };

    return (
        <div className="main-content">
            <div className="roll-zone">
                {rollDone ? (
                    <div className="roll-result">
                        <img src="assets/img/roll-result-side.svg" className="roll-result-side-1" />
                        <img src="assets/img/roll-result-side.svg" className="roll-result-side-2" />
                        <div className="roll-item" style={{ width: ROLL_ITEM_WIDTH }}>
                            <img src={rollWinItem.image} alt={rollWinItem.name} />
                            <p>{rollWinItem.type}<br />{rollWinItem.name}</p>
                            <div className="shadow" style={{ background: rollWinItem.color }}></div>
                        </div>
                    </div>
                ) : (
                    <div className="roll-process">
                        <div className="roll-list" ref={rollListRef} style={{ gap: ROLL_SPACE_BETWEEN }}></div>
                        <div className="roll-overlay"></div>
                        <div className="roll-handle"></div>
                    </div>
                )}
            </div>
            <div className="roll-open">
                {rollDone ? (
                    
                    <button /*onClick={handleSignUpClick} className="btn"*/className="fguol4i010yi">WITHDRAW</button>
                ) : (
                    <button onClick={rollButtonClick}>OPEN</button>
                )}
            </div>
            <Description />
            <LatestSkins />
            {showModal && <Modal onClose={closeModal} />}
        </div>
    );
    
};

export default Main;
// import React, { useState, useEffect, useRef } from 'react';
// import { shuffle_array, random_int } from './utils';
// //import { openModal } from './utils';
// import $ from 'jquery';
// import ROLL_ITEMS from './RollItems';
// import Description from './Description';
// import LatestSkins from './LatestSkins';
// import Modal from './Modal';
// import Image from '../img/Image';
// import caseImage from '../img/caseImg.png';



// const ROLL_DURATION = 10;
// const ROLL_TIMING = 'cubic-bezier(0.25, 0.1, 0.5, 1)';
// const ROLL_NOT_PERSISTENT = false;
// const ROLL_NO_WIN_DISPLAY = false;

// const ROLL_ITEM_WIDTH = 150;
// const ROLL_SPACE_BETWEEN = 16;

// const Main = () => {

    
//     const [rollStarted, setRollStarted] = useState(false);
//     const [rollDone, setRollDone] = useState(false);
//     const [rollWinItem, setRollWinItem] = useState(null);
//     const [rollTotalArray, setRollTotalArray] = useState([]);
//     const rollListRef = useRef(null);

//     useEffect(() => {
//         initRoll();
//     }, []);

//     useEffect(() => {
//         if (rollStarted && rollListRef.current) {
//             const handleTransitionEnd = () => {
//                 if (!rollDone && !ROLL_NO_WIN_DISPLAY) {
//                     setTimeout(() => {
//                         rollOpenResultAfter();
//                     }, 500);
//                 }
//             };
//             const rollListElement = rollListRef.current;
//             rollListElement.addEventListener('transitionend', handleTransitionEnd);

//             return () => {
//                 rollListElement.removeEventListener('transitionend', handleTransitionEnd);
//             };
//         }
//     }, [rollStarted, rollDone]);

//     const initRoll = () => {
//         if (ROLL_ITEMS.length < 2) {
//             $('.roll-zone').html('<p class="roll-error">Service is temporarily unavailable</p>');
//             $('.roll-zone').css('display', '');
//             return;
//         }

//         fillRollZone();
//         fillRollItems();

//         const won = localStorage.getItem('roll_win_item');
//         console.log("won = ", won);
//         if (won && !ROLL_NOT_PERSISTENT) {
//             const wonItem = JSON.parse(won);
//             setRollWinItem(wonItem);
//             rollOpenResultInstant(wonItem);
//             return;
//         }
//     };

//     const fillRollZone = () => {
//         $('.roll-zone').html(`
//             <div class="roll-start">
//                 <img src="assets/img/case.png">
//             </div>
//             <div class="roll-process" style="display: none;">
//                 <div class="roll-list" style="gap: ${ROLL_SPACE_BETWEEN}px"></div>
//                 <div class="roll-overlay"></div>
//                 <div class="roll-handle"></div>
//             </div>
//             <div class="roll-result" style="display: none;"></div>
//         `);
//     };

//     const fillRollItems = () => {
//         console.log("fillRollItems start");
//         const itemsArray = shuffle_array([...ROLL_ITEMS]);

//         let newRollTotalArray = [];
//         for (let i = 0; i < Math.ceil(500 / ROLL_ITEMS.length); i++) {
//             for (let item of itemsArray) {
//                 const shadowStyle = item.color ? `background: ${item.color};` : '';
//                 const hrStyle = item.color ? `background-image: linear-gradient(to right, transparent, ${item.color}, transparent);` : '';

//                 $('.roll-list').append(`
//                     <div class="roll-item" style="width: ${ROLL_ITEM_WIDTH}px">
//                         <img src="${item.image}">
//                         <div class="hr" style="${hrStyle}"></div>
//                         <p>${item.type}<br>${item.name}</p>
//                         <div class="shadow" style="${shadowStyle}"></div>
//                     </div>
//                 `);
//             }
//             newRollTotalArray = newRollTotalArray.concat(itemsArray);
//         }
//         setRollTotalArray(newRollTotalArray);
//     };

//     const rollButtonClick = () => {
//         if (rollDone) {
//             onRollWithdraw();
//             return;
//         }
//         if (rollStarted) {
//             return;
//         }

//         setRollStarted(true);
//         $('.roll-start').css('opacity', '0');
//         $('.roll-open button').attr('disabled', 'disabled');
//         setTimeout(() => {
//             $('.roll-start').css('display', 'none');
//             $('.roll-process').css('opacity', '0').css('display', '');
//             setTimeout(() => {
//                 $('.roll-process').css('opacity', '1');
//                 rollStartMove();
//             }, 1);
//         }, 200);
//     };

//     const rollStartMove = () => {
//         const width = ROLL_ITEM_WIDTH;
//         const space = ROLL_SPACE_BETWEEN;
//         const initPosition = width + width / 2 - space - width * 3 - space * 3;
    
//         const itemsBeforeHandle = 5;
    
//         let index = random_int(400, 400 + ROLL_ITEMS.length);
    
//         let winItem = rollTotalArray[index + itemsBeforeHandle];
    
//         let pickThreshold = 0;
//         while (!winItem.canDrop) {
//             if (pickThreshold > ROLL_ITEMS.length) break;
//             index++;
//             winItem = rollTotalArray[index + itemsBeforeHandle];
//             pickThreshold++;
//         }
//         setRollWinItem(winItem);
//         console.log("ROLL_NOT_PERSISTENT = ", ROLL_NOT_PERSISTENT);
//         if (!ROLL_NOT_PERSISTENT) {
//             console.log("localStorage.setItem = winItem = ", winItem);
//             localStorage.setItem('roll_win_item', JSON.stringify(winItem));
//         }
    
//         const shift = (width + space) * index;
//         const finalPosition = Math.abs(initPosition) + shift;
    
//         $('.roll-list').css('left', `${initPosition}px`);
//         $('.roll-list').css('transition', `all ${ROLL_DURATION}s ${ROLL_TIMING}`);
//         setTimeout(() => {
//             $('.roll-list').css('left', `${-finalPosition}px`);
//         }, 10);
    
//         // Set a timer to call initRoll after ROLL_DURATION + 1 seconds
//         setTimeout(() => {
//             initRoll();
//         }, (ROLL_DURATION + 1) * 1000);
//     };
    
    

//     const rollOpenResult0 = () => {
//         setRollDone(true);
//         if (!rollWinItem) return;

//         const shadowStyle = rollWinItem.color ? `background: ${rollWinItem.color};` : '';

//         $(".roll-result").html(`
//             <img src="assets/img/roll-result-side.svg" class="roll-result-side-1">
//             <img src="assets/img/roll-result-side.svg" class="roll-result-side-2">
//             <div class="roll-item" style="width: ${ROLL_ITEM_WIDTH}px">
//                 <img src="${rollWinItem.image}">
//                 <p>${rollWinItem.type}<br>${rollWinItem.name}</п>
//                 <div class="shadow" style="${shadowStyle}"></div>
//             </div>
//         `);

//         onRollEnded();
//     };

//     const rollOpenResultAfter = () => {
//         console.log("rollOpenResultAfter start");
//         rollOpenResult0();
//         $('.roll-process').css('opacity', '0');
//         setTimeout(() => {
//             $('.roll-process').css('display', 'none');
//             $('.roll-result').css('opacity', '0').css('display', '');
//             setTimeout(() => {
//                 $('.roll-result').css('opacity', '1');
//                 $('.roll-open button').html('WITHDRAW').removeAttr('disabled');
//             }, 1);
//         }, 200);
//     };

//     const rollOpenResultInstant = (winItem) => {
//         setRollDone(true);
//         setRollWinItem(winItem);

//         const shadowStyle = winItem.color ? `background: ${winItem.color};` : '';

//         $(".roll-result").html(`
//             <img src="assets/img/roll-result-side.svg" class="roll-result-side-1">
//             <img src="assets/img/roll-result-side.svg" class="roll-result-side-2">
//             <div class="roll-item" style="width: ${ROLL_ITEM_WIDTH}px">
//                 <img src="${winItem.image}">
//                 <p>${winItem.type}<br>${winItem.name}</п>
//                 <div class="shadow" style="${shadowStyle}"></div>
//             </div>
//         `);

//         $('.roll-start').css('display', 'none');
//         $('.roll-process').css('display', 'none');
//         $('.roll-result').css('display', '');
//         $('.roll-open button').html('WITHDRAW').removeAttr('disabled');
//     };

//     const onRollEnded = () => {
//         let shadowStyle = rollWinItem.color ? `background-image: linear-gradient(to right, transparent, ${rollWinItem.color}, transparent);` : '';
//         $(".latest .list").slick('slickAdd', `
//             <div>
//                 <div class="item">
//                     <p class="time">1 min. ago</p>
//                     <img src="${rollWinItem.image}" class="image">
//                     <div class="name">
//                         <p>${rollWinItem.type}</p>
//                         <p>${rollWinItem.name}</п>
//                     </div>
//                     <div class="shadow" style="${shadowStyle}"></div>
//                 </div>
//             </div>
//         `, 0, 'addBefore');
//     };

    

//     const onRollWithdraw = () => {
//         openModal('wrong');
//     };

//     const [showModal, setShowModal] = useState(false);

//     // const openModal = () => {
//     //     setShowModal(true);
//     // };

//     const closeModal = () => {
//         setShowModal(false);
//     };
//     function openModal(modal) {
//         setShowModal(true);
//         $(".modal").css("display", "none");
//         $(`.modal[data-modal="${modal}"]`)
//             .css("display", "")
//             .css("transform", "scale(0.7)")
//             .css("opacity", "0");
//         $(".modal-container").css("display", "");
//         setTimeout(function () {
//             $(".modal-container").addClass("modal-background");
//             $(`.modal[data-modal="${modal}"]`)
//                 .css("transform", "scale(1)")
//                 .css("opacity", "1");
//         }, 10);
    
//         if (modal == "support") {
//             $('[data-modal="support"] .modal-form input').val("");
//             $('[data-modal="support"] .modal-form textarea').val("");
//         }
//     }

//     return (
//         <div className="main-content">
//             <div className="roll-zone">
//                 {rollDone ? (
//                     <div className="roll-result">
//                         <img src="assets/img/roll-result-side.svg" className="roll-result-side-1" />
//                         <img src="assets/img/roll-result-side.svg" className="roll-result-side-2" />
//                         <div className="roll-item" style={{ width: ROLL_ITEM_WIDTH }}>
//                             <img src={rollWinItem.image} alt={rollWinItem.name} />
//                             <p>{rollWinItem.type}<br />{rollWinItem.name}</p>
//                             <div className="shadow" style={{ background: rollWinItem.color }}></div>
//                         </div>
//                     </div>
//                 ) : (
//                     <div className="roll-process">
//                         <div className="roll-list" ref={rollListRef} style={{ gap: ROLL_SPACE_BETWEEN }}></div>
//                         <div className="roll-overlay"></div>
//                         <div className="roll-handle"></div>
//                     </div>
//                 )}
//             </div>
//             <div className="roll-open">
//                 <button onClick={rollButtonClick}>{rollDone ? 'WITHDRAW' : 'OPEN'}</button>
//             </div>
//             <Description />
//             <LatestSkins />
//             {showModal && <Modal onClose={closeModal} />}
//         </div>
//     );
// };

// export default Main;
