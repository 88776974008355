// components/Header.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Header = () => {
  const [auth, setAuth] = useState({ loggedIn: false, username: '' });

  useEffect(() => {
    axios.get('/auth.php')
      .then(response => {
        setAuth(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the auth data!', error);
      });
  }, []);

  const handleSignUpClick = () => {
    window.location.href = '/mapper.php';
  };

  return (
    <header>
      <div className="logo">
        <img src="../assets/img/logo.png" alt="Logo" />
        <p>voltSkins</p>
      </div>
      <div className="right">
        <nav aria-label="header">
          <Link to="/">Home</Link>
          <Link to="/about">About Us</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/terms">User Agreement</Link>
        </nav>
        <div className="hr"></div>
        {auth.loggedIn ? (
          <>
            <span className="signout">{auth.username}</span>
            <a href="/logout.php" className="signout"> | Logout</a>
          </>
        ) : (
          <>
            <a href="/mapper.php" className="signin">Sign in</a>
            <button className="signup fguol4i010yi" /*onClick={handleSignUpClick}*/>
              <img src="assets/img/steam.svg" alt="Steam" />
              <p>Sign in</p>
            </button>
          </>
        )}
      </div>
      <button className="menu">
        <img src="../assets/img/menu.svg" alt="Menu" />
      </button>
    </header>
  );
};

export default Header;