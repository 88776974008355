// utils.js
export const shuffle_array = (array) => {
    let currentIndex = array.length, randomIndex;

    while (currentIndex > 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
};

export const random_int = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const openModal = (modal) => {
    const modalElement = document.querySelector(`.modal[data-modal="${modal}"]`);
    const modalContainer = document.querySelector(".modal-container");

    modalContainer.style.display = '';
    modalElement.style.display = '';

    setTimeout(() => {
        modalContainer.classList.add("modal-background");
        modalElement.style.transform = 'scale(1)';
        modalElement.style.opacity = '1';
    }, 10);
};
