// components/Cookie.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Cookie = () => {
  const [isAccepted, setIsAccepted] = useState(false);

  useEffect(() => {
    const cookieAccepted = localStorage.getItem('cookieAccepted');
    if (cookieAccepted === 'true') {
      setIsAccepted(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookieAccepted', 'true');
    setIsAccepted(true);
  };

  if (isAccepted) {
    return null;
  }

  return (
    <div className="cookieContainer" id="cookieConsent" style={{ display: 'block' }}>
      <div className="interviewTitle">
        <a style={{ color: 'white' }}>Cookie</a>
      </div>
      <div className="interviewDesc">
        <p>
          We use cookies. By continuing to use the site, you agree to{' '}
          <Link to="/privacy" style={{ color: 'white' }}>Privacy Policy</Link>{' '}
          and{' '}
          <Link to="/terms" style={{ color: 'white' }}>User Agreement</Link>.
        </p>
      </div>
      <div className="cookieButtonY">
        <a id="cookieOkBtn" style={{ color: 'white', cursor: 'pointer' }} onClick={handleAcceptCookies}>
          Accept
        </a>
      </div>
    </div>
  );
};

export default Cookie;
