// Privacy.js
import React from 'react';

const Privacy = () => (
  <div className="">
    <div className="description">
    <p className="title">Privacy Policy of <span className="name">VOLTSKINS</span></p>
    </div>
    <p className="text">
      We kindly ask you to familiarize yourself with the current rules for processing personal data concerning visitors and users of the voltSkin service.
    </p>
    <p>
      Personal data refers to information used to identify an individual. By providing your personal data, you agree to its storage and further processing. This data will not be shared with third parties.
    </p>
    <p>
      During the registration process on the voltSkin website, you consent to the processing and storage of the following data:
    </p>
    <ul className='terms-list'>
      <li>Email addresses: Necessary for user identification procedures;</li>
      <li>Information about your site visits, including IP address, operating system, and login time and date: This information is used solely for analysis and maintaining the website at a high-quality level;</li>
      <li>Information about cookies: These small text files are generated by the site using your browser and stored on your device. They do not contain any personal data about you. The service uses these files to facilitate site use and to gather statistical data more efficiently.</li>
    </ul>
    <p>
      By using the voltSkin service, you automatically agree to the terms described above. If you have any questions regarding the processing of personal information, you can contact us at support@volt.skin.
    </p>
  </div>
);

export default Privacy;
